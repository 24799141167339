import { navigate } from 'gatsby'
import { useIdentity } from '../providers/identityContext'
import { windowExists } from '../utils'

function Login() {
  const { loggedIn, login } = useIdentity()

  if (!windowExists) {
    return null
  }

  if (loggedIn) {
    navigate('/')
    return null
  }

  login()
  return null
}

export default Login
